import React from 'react'
import clsx from 'clsx'

import styles from './Kbd.module.css'

type KbdProps = React.HTMLAttributes<HTMLElement> & {
  className?: string
  children: React.ReactNode
}

const Kbd = React.forwardRef<HTMLElement, KbdProps>(
  ({ children, className, ...props }: KbdProps, forwardedRef) => {
    return (
      <kbd
        ref={forwardedRef}
        className={clsx(styles.kbd, className)}
        {...props}
      >
        {children}
      </kbd>
    )
  },
)

Kbd.displayName = 'Kbd'

export { Kbd, KbdProps }
