import React from 'react'
import { Text } from '../Text'

type ParagraphProps = Omit<React.ComponentProps<typeof Text>, 'as' | 'ref'>

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  (props, forwardedRef) => {
    return <Text as="p" size="sm" align="left" {...props} ref={forwardedRef} />
  },
)

Paragraph.displayName = 'Paragraph'

export { Paragraph, ParagraphProps }
