import React, { forwardRef } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import usePortalContainer from '../../helpers/usePortalContainer'

import styles from './Tooltip.module.css'

type TooltipElement = React.ElementRef<typeof TooltipPrimitive.Content>
type TooltipProps = {
  /**
   * Content displayed inside the tooltip
   */
  content: React.ReactNode
  /**
   * Children is the trigger of the tooltip
   */
  children: React.ReactNode
  /**
   * Side the tooltip is displayed on
   * @default 'top'
   */
  side?: 'top' | 'right' | 'bottom' | 'left'
  /**
   * Align the tooltip
   * @default 'center'
   */
  align?: 'start' | 'center' | 'end'
  /**
   * Delay in ms before the tooltip is shown
   * @default 300
   */
  delay?: number
  /**
   * Controls whether the arrow is displayed
   * @default false
   */
  arrow?: boolean
  /**
   * Controls whether the tooltip is open
   */
  open?: boolean
  /**
   * Controls whether the tooltip is open by default
   * @default false
   */
  defaultOpen?: boolean
  /**
   * Callback when the tooltip is opened or closed
   */
  onOpenChange?: (open: boolean) => void
}

const Tooltip = forwardRef<TooltipElement, TooltipProps>(
  (
    {
      content,
      children,
      side = 'top',
      align = 'center',
      delay = 300,
      arrow = false,
      open,
      defaultOpen,
      onOpenChange,
    }: TooltipProps,
    ref,
  ) => {
    const container = usePortalContainer()

    return (
      <TooltipPrimitive.Root
        delayDuration={delay}
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal container={container}>
          <TooltipPrimitive.Content
            ref={ref}
            className={styles.content}
            side={side}
            align={align}
            hideWhenDetached
          >
            {content}
            {arrow && <TooltipPrimitive.Arrow className={styles.arrow} />}
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    )
  },
)
Tooltip.displayName = 'Tooltip'

export { Tooltip }
export type { TooltipProps }
