import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import styles from './Avatar.module.css'

// TODO: replace with SVG
const DEFAULT_AVATAR = `https://s3.amazonaws.com/buffer-ui/Default+Avatar.png`

type AvatarSize = 'xsmall' | 'small' | 'medium' | 'large'

interface AvatarProps
  extends Omit<React.HTMLProps<HTMLImageElement>, 'size' | 'src' | 'alt'> {
  /**
   * Size of the avatar
   * @default medium
   */
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  /**
   * Source of the avatar image
   */
  src?: string
  /**
   * Alt text for the avatar image, pass `""` if the avatar is purely decorative
   */
  alt: string
}

const Avatar = React.forwardRef<HTMLImageElement, AvatarProps>(
  (
    { size = 'medium', className, src, alt, ...props }: AvatarProps,
    forwardedRef,
  ) => {
    const [innerSrc, setInnerSrc] = useState(src)

    useEffect(() => {
      setInnerSrc(src)
    }, [src])

    return (
      <img
        ref={forwardedRef}
        className={clsx(styles.avatar, styles[size], className)}
        src={innerSrc ?? DEFAULT_AVATAR}
        alt={alt}
        onError={() => setInnerSrc(DEFAULT_AVATAR)}
        {...props}
      />
    )
  },
)

Avatar.displayName = 'Avatar'

export { Avatar, AvatarProps, AvatarSize }
