import React, { forwardRef } from 'react'
import clsx from 'clsx'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import styles from './Tabs.module.css'

type TabsElement = React.ElementRef<typeof TabsPrimitive.Tabs>
type TabList = React.ElementRef<typeof TabsPrimitive.TabsList>
type TabElement = React.ElementRef<typeof TabsPrimitive.TabsTrigger>
type TabPanel = React.ElementRef<typeof TabsPrimitive.TabsContent>
type TabsSize = 'small' | 'medium'

type TabsProps = {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  /**
   * The value of the tab that should be active when initially rendered.
   */
  defaultValue?: string
  /**
   * The controlled value of the tab to activate. Should be used in conjunction with onChange.
   */
  value?: string
  /**
   * The size of the tabs
   * @default 'medium'
   */
  size?: TabsSize
  /**
   * Event handler called when the value changes
   */
  onChange?: (value: string) => void
} & React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>

const Tabs = forwardRef<TabsElement, TabsProps>(
  (
    {
      children,
      value,
      size = 'medium',
      defaultValue,
      onChange,
      className,
      ...props
    }: TabsProps,
    ref,
  ) => {
    return (
      <TabsPrimitive.Root
        {...props}
        value={value}
        defaultValue={defaultValue}
        onValueChange={onChange}
        className={clsx(styles.tabs, styles[size], className)}
        ref={ref}
      >
        {children}
      </TabsPrimitive.Root>
    )
  },
)

Tabs.displayName = 'Tabs'

type TabsListProps = {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  className?: string
}

const TabList = forwardRef<TabList, TabsListProps>(
  ({ children, className }: TabsListProps, ref) => {
    return (
      <TabsPrimitive.List
        ref={ref}
        data-tablist
        className={clsx(styles.tabList, className)}
      >
        {children}
      </TabsPrimitive.List>
    )
  },
)

TabList.displayName = 'Tabs.TabList'

type TabProps = {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  /**
   * A unique value that associates the tab with a panel.
   */
  value: string
  /**
   * When true, prevents the user from interacting with the tab.
   */
  disabled?: boolean
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
  className?: string
  id?: string
}

const Tab = forwardRef<TabElement, TabProps>(
  ({ children, value, id, disabled, className, asChild }: TabProps, ref) => {
    return (
      <TabsPrimitive.TabsTrigger
        id={id}
        value={value}
        disabled={disabled}
        asChild={asChild}
        className={clsx(styles.tab, className)}
        ref={ref}
      >
        {children}
      </TabsPrimitive.TabsTrigger>
    )
  },
)

Tab.displayName = 'Tabs.Tab'

type TabPanelProps = {
  /**
   * Children are the elements in the toggle group
   */
  children: React.ReactNode
  /**
   * A unique value that associates the panel with a tab.
   */
  value: string
  /**
   * Change the default rendered element for the one passed as a child, merging their props and behavior.
   */
  asChild?: boolean
  id?: string
}

const Panel = forwardRef<TabPanel, TabPanelProps>(
  ({ children, asChild, value, id }: TabPanelProps, ref) => {
    return (
      <TabsPrimitive.TabsContent
        id={id}
        asChild={asChild}
        value={value}
        ref={ref}
        className={styles.panel}
      >
        {children}
      </TabsPrimitive.TabsContent>
    )
  },
)

Panel.displayName = 'Tabs.Panel'

const TabsObject = Object.assign(Tabs, { TabList, Tab, Panel })
export { TabsObject as Tabs }
export type { TabsProps, TabProps, TabPanelProps }
