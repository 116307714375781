import * as React from 'react'
import clsx from 'clsx'

import styles from './Link.module.css'

type LinkElement = React.ElementRef<'a'>
type LinkProps = React.ComponentPropsWithoutRef<'a'> & {
  /**
   * Will set the link to open in a new tab
   * with `rel="noopener noreferrer"`
   * @default false
   */
  external?: boolean
  /**
   * Link contents
   */
  children: React.ReactNode
  /**
   * Additional classname applied to the link
   */
  className?: string
}

const Link = React.forwardRef<LinkElement, LinkProps>(
  (
    { children, rel, target, external = false, className, ...props }: LinkProps,
    forwardedRef,
  ) => (
    <a
      {...props}
      ref={forwardedRef}
      rel={external ? 'noopener noreferrer' : rel}
      target={external ? '_blank' : target}
      className={clsx(styles.link, className)}
    >
      {children}
    </a>
  ),
)

Link.displayName = 'Link'

export { Link }
export type { LinkProps }
