import React, { ElementRef } from 'react'
import clsx from 'clsx'

import {
  FacebookIcon,
  IconProps,
  InstagramIcon,
  LinkedinIcon,
  MastodonIcon,
  PinterestIcon,
  ShopifyIcon,
  StartPageIcon,
  BlueskyIcon,
  ThreadsIcon,
  TiktokIcon,
  TwitterIcon,
  WarningIcon,
  YoutubeIcon,
  GbpIcon,
} from '../icons'

import styles from './ChannelIcon.module.css'

type ChannelType =
  | 'facebook'
  | 'googlebusiness'
  | 'instagram'
  | 'linkedin'
  | 'mastodon'
  | 'pinterest'
  | 'shopify'
  | 'startPage'
  | 'tiktok'
  | 'twitter'
  | 'youtube'
  | 'x'
  | 'threads'
  | 'bluesky'

const channelIconByType = {
  facebook: FacebookIcon,
  // todo: rename!!
  googlebusiness: GbpIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  mastodon: MastodonIcon,
  pinterest: PinterestIcon,
  shopify: ShopifyIcon,
  startPage: StartPageIcon,
  tiktok: TiktokIcon,
  twitter: TwitterIcon,
  x: TwitterIcon,
  youtube: YoutubeIcon,
  threads: ThreadsIcon,
  bluesky: BlueskyIcon,
}

type ChannelIconProps = Omit<IconProps, 'color'> & {
  /**
   * Type of the channel
   */
  type: ChannelType
  /**
   * Color of the icon, use `branded` for the branded color of the channel
   */
  color?: 'default' | 'subtle' | 'inverted' | 'branded'
}

const ChannelIcon = React.forwardRef<ElementRef<'svg'>, ChannelIconProps>(
  ({ type, color, ...props }: ChannelIconProps, forwardedRef) => {
    // TODO: when we have question mark icon, warning icon should be replaced with it
    const Icon = channelIconByType[type] ?? WarningIcon

    return (
      <Icon
        ref={forwardedRef}
        {...props}
        data-channel={type}
        color={color !== 'branded' ? color : undefined}
        className={clsx(color === 'branded' && styles.withColor)}
      />
    )
  },
)

ChannelIcon.displayName = 'ChannelIcon'

export { ChannelIcon, ChannelIconProps, ChannelType }
