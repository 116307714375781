import React from 'react'
import clsx from 'clsx'

import { Avatar, AvatarProps } from '../Avatar/Avatar'
import { ChannelIcon, ChannelType } from '../ChannelIcon'

import styles from './ChannelAvatar.module.css'

type ChannelAvatarProps = Omit<AvatarProps, 'ref'> & {
  /**
   * Channel type, used to determine the icon
   */
  channel: ChannelType
  /**
   * Size of the avatar
   * @default medium
   */
  size?: AvatarProps['size']
  /**
   * Alternative text for the avatar image, set to `""` if the avatar is decorative
   */
  alt?: string
  /**
   * Source of the avatar image
   */
  src?: string
}

const iconSizeByAvatarSize = {
  xsmall: 8,
  small: 10,
  medium: 12,
  large: 16,
}

const ChannelAvatar = React.forwardRef<HTMLDivElement, ChannelAvatarProps>(
  (
    { channel, className, size = 'medium', ...props }: ChannelAvatarProps,
    forwardedRef,
  ) => {
    return (
      <div
        ref={forwardedRef}
        data-channel={channel}
        className={clsx(styles.wrapper, styles[size], className)}
      >
        <Avatar size={size} {...props} />
        <div className={styles.icon}>
          <ChannelIcon
            type={channel}
            color="inverted"
            size={iconSizeByAvatarSize[size]}
          />
        </div>
      </div>
    )
  },
)

ChannelAvatar.displayName = 'ChannelAvatar'

export { ChannelAvatar, ChannelAvatarProps }
